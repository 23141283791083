
@font-face {
  font-family: "Manage Thin";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Thin.ttf");
}

.text-thin {
  font-family: "Manage Thin";
}

@font-face {
  font-family: "Manage ThinItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ThinItalic.ttf");
}

.text-thin-italic {
  font-family: "Manage ThinItalic";
}

@font-face {
  font-family: "Manage ExtraLight";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraLight.ttf");
}

.text-extra-light {
  font-family: "Manage ExtraLight";
}

@font-face {
  font-family: "Manage ExtraLightItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraLightItalic.ttf");
}

.text-extra-light-italic {
  font-family: "Manage ExtraLightItalic";
}

@font-face {
  font-family: "Manage Light";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Light.ttf");
}

.text-light {
  font-family: "Manage Light";
}

@font-face {
  font-family: "Manage LightItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-LightItalic.ttf");
}

.text-light-italic {
  font-family: "Manage LightItalic";
}

@font-face {
  font-family: "Manage Regular";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Regular.ttf");
}

.text-regular {
  font-family: "Manage Regular";
}

@font-face {
  font-family: "Manage Italic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Italic.ttf");
}

.text-italic {
  font-family: "Manage Italic";
}

@font-face {
  font-family: "Manage Medium";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Medium.ttf");
}

.text-medium {
  font-family: "Manage Medium";
}

@font-face {
  font-family: "Manage MediumItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-MediumItalic.ttf");
}

.text-medium-italic {
  font-family: "Manage MediumItalic";
}


@font-face {
  font-family: "Manage SemiBold";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-SemiBold.ttf");
}

.text-semi-bold {
  font-family: "Manage SemiBold";
}

@font-face {
  font-family: "Manage SemiBoldItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-SemiBoldItalic.ttf");
}

.text-semi-bold-italic {
  font-family: "Manage SemiBoldItalic";
}

@font-face {
  font-family: "Manage Bold";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Bold.ttf");
}

.text-bold {
  font-family: "Manage Bold";
}


@font-face {
  font-family: "Manage BoldItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-BoldItalic.ttf");
}

.text-bold-italic {
  font-family: "Manage BoldItalic";
}


@font-face {
  font-family: "Manage ExtraBold";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraBold.ttf");
}

.text-extra-bold {
  font-family: "Manage ExtraBold";
}

@font-face {
  font-family: "Manage ExtraBoldItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}

.text-extra-bold-italic {
  font-family: "Manage ExtraBoldItalic";
}

@font-face {
  font-family: "Manage Black";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-Black.ttf");
}

.text-Black {
  font-family: "Manage Black";
}

@font-face {
  font-family: "Manage BlackItalic";
  src: url("../src/App/Assets/Fonts/Poppins/Poppins-BlackItalic.ttf");
}



.primary-color{
  color: #07B553;
}

.secondary-color{
  color: #069142;
}

.plus-button:hover{
  background-color: #069142 !important;
  border-color:  #069142 !important;
}

.add-button{
  background-color: #07B553 !important;
  color: #FFFFFF !important;
  border-color:  #07B553 !important;
}
.add-button:hover{
  background-color: #166534 !important;
  color: #FFFFFF !important;
  border-color:  #166534 !important;
}

.form-save-button{
  background-color: #FFFFFF !important;
  color: #07B553 !important;
  border-color:  #07B553 !important;
}
.form-save-button:hover{
  background-color: #FFFFFF !important;
  color: #14532d !important;
  border-color:  #14532d !important;
}

.form-cancel-button{
  background-color: #FFFFFF !important;
  color: #ef4444 !important;
  border-color:  #ef4444 !important;
}
.form-cancel-button:hover{
  background-color: #FFFFFF !important;
  color: #b91c1c !important;
  border-color:  #b91c1c !important;
}

.form-back-button{
  background-color: #FFFFFF !important;
  color: #4b4b4b !important;
  border-color:  #4b4b4b !important;
}
.form-back-button:hover{
  background-color: #FFFFFF !important;
  color: #000000 !important;
  border-color:  #000000 !important;
}

.breadcrumb-tab-color {
  color: #8C8C8C !important;
}
.breadcrumb-tab-color:hover {
  color: #2d53da !important;
}




/* .page-a4{
  background-color: #FFFFFF;
  width: 8.27in;
  height: 11.69in;
  padding: 1.6cm;
  position: relative;
 }
 .page-a4-footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right:  0;
  padding: 1.5rem;
  background-color: white;
} 
.page-footer{
  position: absolute;
   bottom: 0;
   left: 0;
   right:  0;
   padding: 1.5rem;
   background-color: white;
 } */



 /* autocomplete placeholder */
:where(.css-dev-only-do-not-override-1okl62o).ant-select-single {
  text-align: left !important;
}

/*text wrap*/
.text-wrap-limit{
  max-width: 175px;        
  white-space: nowrap;    
  overflow: hidden;        
  text-overflow: ellipsis; 
  position: relative;      
  cursor: pointer;         
}

.text-wrap-limit:hover::after {
  content: attr(title);   
  position: absolute;      
  left: 0;
  top: 100%;              
  background: rgba(0, 0, 0, 0.8); 
  color: #fff; 
  padding: 5px;       
  border-radius: 4px;    
  white-space: nowrap; 
  z-index: 1000;       
  font-size: 0.875rem; 
  max-width: 400px; 
  overflow-wrap: break-word; 
}


.hidden-on-screen {
  display: none; /* Hides the component from view */
}

@media print {
  .hidden-on-screen {
    display: block !important; /* Makes it visible only when printing */
  }
}
